
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Services extends Vue {
  services = [
    {
      title: 'Coaching',
      image: '/images/coaching.jpg',
      description: 'Nous allons passer 1 à 2H en rendez-vous individuel à échanger sur tes envies déco ou ta/tes problématique(s) et je te proposerai des alternatives à travers des croquis.',
      formules: [
        '- Conseils personnalisés',
        '%s(couleur, tendance, matériaux, agencement)',
        '- Croquis'
      ],
      prixMini: '100'
    },
    {
      title: 'Home Staging',
      image: '/images/homestaging.jpeg',
      description: 'Envie de vendre plus rapidement ou de donner un second souffle à ton intérieur ? Je revalorise les espaces et sélectionne ton mobilier déjà existant, en lui donnant un second souffle ou non, pour réaménager ton bien. Je te donnerai bien-sûr mes petits conseils pour y parvenir lors d\'un rendez-vous de 1 à 2 heures.',
      formules: [
        '- Conseils personnalisés',
        '%s(couleur, tendance, matériaux, agencement)',
        '- Croquis'
      ],
      prixMini: '100'
    },
    {
      title: 'Optimisation',
      image: '/images/amenagement.jpeg',
      description: 'Fais moi confiance, je te présenterais une ou plusieurs alternatives pour optimiser ton espace et pour trouver une suggestion de décoration.',
      formules: [
        '- Plans à l\'échelle',
        '- Visuels 3D'
      ],
      prixMini: '250'
    },
    {
      title: 'Aménagement',
      image: '/images/shopping.jpg',
      description: 'Nous discutons de tes goûts et de tes envies, et à travers les tendances actuelles, nous créons ensemble une décoration singulière. Je réalise alors une liste shopping détaillée avec les liens de chaque mobilier et de la petite déco pour que tu n\'ai plus qu\'à faire tes achats.',
      formules: [
        '- Plans à l\'échelle',
        '- Planche tendance',
        '- Visuels 3D',
        '- Liste shopping'
      ],
      prixMini: '300'
    },
  ];

  details = [
    {
      title: "Planche matériaux - shopping",
      image: "/images/details/1.png",
      info: "Cette planche regroupe les matériaux et le mobilier pour te donner une vue d'ensemble. En plus de cela, les références sont notifiées dessous, tu n'as plus besoin de te prendre la tête sur les recherches, c'est moi qui prend les devants !"
    },
    {
      title: "Planche tendance",
      image: "/images/details/2.png",
      info: "Cette planche est utile pour voir l'atmosphère générale de l'aménagement futur de ta pièce.\nElle est constituée d'images te permettant de te projeter rapidement. Elle englobe les différentes idées et styles pour ne créer qu'une unique ambiance."
    },
    {
      title: "Visuel 3D",
      image: "/images/details/3.png",
      info: "C'est un regroupement de dessins reconstituant ta pièce de différents points de vue pour te rendre compte des espaces utilisés et du rendu illustré de ton futur projet."
    },
    {
      title: "Plan à l'échelle",
      image: "/images/details/4.png",
      info: "Il permet de mieux visualiser comment les pièces sont disposées et de l'aménagement futur. Toutes les mesures sont indiquées pour réaliser un projet réaliste. "
    },
  ]
}
