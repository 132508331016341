<template>
  <div class="-mt-20 -ml-4 absolute w-full text-2xl font-display text-center">- Cookies et Informations personnelles -</div>

  <div class="md:flex-1 p-4 bg-white font-light shadow-md m-2">
    Ce site n'utilises aucun Cookie et ne collecte aucune de vos données personnelles.<br>
    Le Stockage Local est parfois utilisé par votre navigateur afin de procédé à la mise en cache du site web pour vous permettre de le charger plus rapidement lors de vos futures visites.<br><br>

    Les données transmises par le formulaire de devis sont automatiquements transmises par email et sont automatiquement supprimées de notre hébergeur de manière régulière.<br>
    Ces données ne sont communiquées à aucun services tier.<br>
    Notre hébergeur n'a pas d'accès direct à ces données.
  </div>

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Cookies extends Vue {

}
</script>
