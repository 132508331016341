<template>
  <div class="-mt-20 -ml-4 absolute w-full text-2xl font-display text-center">- Ton devis en quelques cliques -</div>

  <form name="devis" subject="Nouveau devis !" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
    <input class="hidden" type="hidden" name="form-name" value="devis" />
    <div class="flex flex-wrap">
      <div :class="{active: !empty('nom')}" class="flex-1 input-black">
        <span class="inputlabel">Nom et Prénom</span>
        <input name="Nom" v-model="form.nom" class="inputzone" type="text" required>
        <div class="inputnotice opacity-0">=)</div>
      </div>
      <div :class="{active: !empty('tel')}" class="flex-1 input-black">
        <span class="inputlabel">Téléphone</span>
        <input v-model="form.tel" class="inputzone" type="tel">
        <div class="inputnotice opacity-0">=)</div>
      </div>
    </div>

    <div class="flex">
      <div :class="{active: !empty('mail')}" class="flex-1 input-black">
        <span class="inputlabel">E-mail</span>
        <input name="Email" v-model="form.mail" class="inputzone" type="email" required>
        <div class="inputnotice opacity-0">=)</div>
      </div>
    </div>

    <div class="flex">
      <div :class="{active: !empty('ville')}" class="flex-1 input-black">
        <span class="inputlabel">Ville + Adresse</span>
        <input name="Adresse" v-model="form.ville" class="inputzone" type="text">
        <div class="inputnotice opacity-0">=)</div>
      </div>
    </div>

    <div class="flex">
    </div>

    <div class="flex flex-wrap">
      <div class="flex-1 input-black active">
        <span class="inputlabel">Voues êtes :</span>
        <select name="Status" v-model="form.status" class="inputzone">
          <option value="Propriétaire">Propriétaire</option>
          <option value="Locataire">Locataire</option>
        </select>
      </div>
      <div class="flex-1 input-black active">
        <span class="inputlabel">Votre projet concerne :</span>
        <select name="Lieu" v-model="form.type" class="inputzone">
          <option value="maison">Une maison</option>
          <option value="appartement">Un appartement</option>
          <option value="espace public">Un espace public</option>
          <option value="espace professionnel">Un espace professionnel</option>
        </select>
      </div>
      <div :class="{active: !empty('superficie')}" class="flex-1 input-black">
        <span class="inputlabel">Superficie concernée (en m²)</span>
        <input name="Superficie" v-model="form.superficie" class="inputzone" type="text" required>
        <div class="inputnotice opacity-0">=)</div>
      </div>
      <div style="flex-basis: 100%;"></div>
      <div :class="{active: !empty('pieces')}" class="flex-1 input-black">
        <span class="inputlabel">Pièces concernées</span>
        <textarea name="Pièces" v-model="form.pieces" class="inputzone" required></textarea>
        <div class="inputnotice opacity-0">=)</div>
      </div>
    </div>

    <div class="flex">
      <div :class="{active: !empty('desc')}" class="flex-1 input-black">
        <span class="inputlabel">Décrivez votre Projet succintement :</span>
        <textarea name="Description" v-model="form.desc" class="inputzone" required></textarea>
        <div class="inputnotice opacity-0">=)</div>
      </div>
    </div>

    <button class="button-indigo-light font-normal mt-4 w-full">Demander un devis</button>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Devis extends Vue {
  form : {[x in string]: any} = {
    nom: '',
    tel: '',
    mail: '',
    ville: '',
    status: 'Propriétaire',
    type: 'maison',
    superficie: '',
    pieces: '',
    desc: ''
  }

  empty(input: string) {
    return this.form[input] == '';
  }
}
</script>
