
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Projets extends Vue {
  display?: string;

  invalidFile() {
    this.display = undefined;
  }

  beforeMount() {
    this.display = this.$route.params.file as string;
  }


  projets = [
    {title:"Cahier de tendance 2020", image:"", lien:"cahier-tendance-2020"},
    {title:"Un salon de coiffure qui change de tête !", image:"", lien:"salon-coiffure"},
    {title:"Un coliving haut de gamme et haut en couleurs en plein coeur de Lille", image:"", lien:"coliving"},
  ]
}
