<template>
  <div v-if="display" class="font-display text-2xl text-center p-4">
    {{ projets.find((el) => {if (el.lien == display) return el; return undefined}).title }}
  </div>
  <div v-if="display" class="pb-8">
    <a v-bind:href="`/projets/${display}.pdf#view=fit&scrollbar=1`" target="_blank">
      <button class="button-white-invert font-display text-black uppercase text-sm block mx-auto mt-2 mb-2">Voir le PDF du projet</button>
    </a>
    <p class="text-center text-sm text-gray-500 italic">Sur mobile, lancera le téléchargement du fichier.</p>
  </div>
  <div v-if="display" class="font-display text-2xl text-center p-4">
    - Tous mes projets -
  </div>
  <div v-if="!display" class="-mt-20 -ml-4 absolute w-full text-2xl font-display text-center">
    - L'art de vivre -
  </div>
  
  <div class="text-md text-center font-light">Je te conseille de prendre un petit café pendant que tu découvres mes différents projets !</div>

  
  <div class="flex flex-col md:flex-row flex-wrap justify-center p-4">
    <div v-for="projet in projets" :key="projet" class="relative w-80 shadow-md m-2">
      <router-link :to="`/projets/${projet.lien}`">
        <div class="w-full h-full bg-white">
          <div class="bg-white w-6 h-6 absolute top-0 right-0 shadow-sm" style="border-top : 1.5rem solid #E0E0E0; border-left : 1.5rem solid transparent;"></div>
          <div :style="`background-image: url(${projet.image})`" class="w-full bg-cover"></div>
          <div class="uppercase w-full flex justify-center items-center">
            <span class="font-display p-4">{{ projet.title }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Projets extends Vue {
  display?: string;

  invalidFile() {
    this.display = undefined;
  }

  beforeMount() {
    this.display = this.$route.params.file as string;
  }


  projets = [
    {title:"Cahier de tendance 2020", image:"", lien:"cahier-tendance-2020"},
    {title:"Un salon de coiffure qui change de tête !", image:"", lien:"salon-coiffure"},
    {title:"Un coliving haut de gamme et haut en couleurs en plein coeur de Lille", image:"", lien:"coliving"},
  ]
}
</script>
