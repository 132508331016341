<template>
  <div class="-mt-20 -ml-4 absolute w-full text-2xl text-center font-display">- Il en faut pour tout le monde -</div>

  <div class="text-md text-center font-light">Peu importe ton projet ou ton budget, je m'adapte à ta situation ! 
  <br>C'est pourquoi je propose différents forfaits
  <br>qui te permettront de trouver ce qui te correspond le mieux.</div>

  <div class="flex flex-col md:flex-row flex-wrap justify-center p-4">
    <div v-for="service in services" :key="service" class="relative w-80 group shadow-md m-2" tabindex="0">
      <div class="absolute z-10 w-full h-full bg-white group-hover:opacity-0 hover:opacity-0 group-focus:opacity-0">
        <div class="bg-white w-6 h-6 absolute top-0 right-0 shadow-sm" style="border-top : 1.5rem solid #E0E0E0; border-left : 1.5rem solid transparent;"></div>
        <div :style="`background-image: url(${service.image})`" class="w-full h-5/6 bg-cover"></div>
        <div class="uppercase w-full flex justify-center items-center h-1/6">
          <span class="font-display">{{ service.title }}</span>
        </div>
      </div>
      <div class="w-full h-full p-4 bg-white">
        <div class="h-5/6 mb-4 flex flex-col">
          <div class="font-bold">Formule "{{ service.title }}" :</div>
          <div class="text-justify text-sm mt-2 mb-4">{{ service.description }}</div>
          <ul class="font-semibold mt-auto">
            Cette formule contient :
            <li v-for="formule in service.formules" :key="formule" :class="formule.startsWith('%s') ? 'text-sm' : ''">
              {{ formule.startsWith('%s') ? formule.slice(2) : formule }}
            </li>
          </ul>
        </div>
        <div class="flex justify-center items-center h-1/6">
          <div class="absolute italic text-sm" style="transform: translateY(-100%)">à partir de</div>
          <div class="font-bold text-xl">{{ service.prixMini }}<span class="text-xs absolute mt-2"> €</span></div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="text-xl text-center font-display">- Détails -</div>

  <div v-for="(detail, index) in details" :key="detail" class="md:flex my-4 pb-8">
    <div class="aspect-x-16 aspect-y-9 md:w-7/12" :class="index%2 == 0 ? 'block' : 'md:hidden'"><img class="w-full" :src="detail.image"></div>
    <div class="py-4 md:py-0 md:px-4 flex-1" :class="{ 'text-right': index%2 == 1 }">
      <div class="text-xl pb-4 font-bold">{{ detail.title }}</div>
      <div>{{ detail.info }}</div>
    </div>
    <div class="aspect-x-16 aspect-y-9 hidden md:block md:w-7/12" v-if="index%2 == 1"><img class="w-full" :src="detail.image"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Services extends Vue {
  services = [
    {
      title: 'Coaching',
      image: '/images/coaching.jpg',
      description: 'Nous allons passer 1 à 2H en rendez-vous individuel à échanger sur tes envies déco ou ta/tes problématique(s) et je te proposerai des alternatives à travers des croquis.',
      formules: [
        '- Conseils personnalisés',
        '%s(couleur, tendance, matériaux, agencement)',
        '- Croquis'
      ],
      prixMini: '100'
    },
    {
      title: 'Home Staging',
      image: '/images/homestaging.jpeg',
      description: 'Envie de vendre plus rapidement ou de donner un second souffle à ton intérieur ? Je revalorise les espaces et sélectionne ton mobilier déjà existant, en lui donnant un second souffle ou non, pour réaménager ton bien. Je te donnerai bien-sûr mes petits conseils pour y parvenir lors d\'un rendez-vous de 1 à 2 heures.',
      formules: [
        '- Conseils personnalisés',
        '%s(couleur, tendance, matériaux, agencement)',
        '- Croquis'
      ],
      prixMini: '100'
    },
    {
      title: 'Optimisation',
      image: '/images/amenagement.jpeg',
      description: 'Fais moi confiance, je te présenterais une ou plusieurs alternatives pour optimiser ton espace et pour trouver une suggestion de décoration.',
      formules: [
        '- Plans à l\'échelle',
        '- Visuels 3D'
      ],
      prixMini: '250'
    },
    {
      title: 'Aménagement',
      image: '/images/shopping.jpg',
      description: 'Nous discutons de tes goûts et de tes envies, et à travers les tendances actuelles, nous créons ensemble une décoration singulière. Je réalise alors une liste shopping détaillée avec les liens de chaque mobilier et de la petite déco pour que tu n\'ai plus qu\'à faire tes achats.',
      formules: [
        '- Plans à l\'échelle',
        '- Planche tendance',
        '- Visuels 3D',
        '- Liste shopping'
      ],
      prixMini: '300'
    },
  ];

  details = [
    {
      title: "Planche matériaux - shopping",
      image: "/images/details/1.png",
      info: "Cette planche regroupe les matériaux et le mobilier pour te donner une vue d'ensemble. En plus de cela, les références sont notifiées dessous, tu n'as plus besoin de te prendre la tête sur les recherches, c'est moi qui prend les devants !"
    },
    {
      title: "Planche tendance",
      image: "/images/details/2.png",
      info: "Cette planche est utile pour voir l'atmosphère générale de l'aménagement futur de ta pièce.\nElle est constituée d'images te permettant de te projeter rapidement. Elle englobe les différentes idées et styles pour ne créer qu'une unique ambiance."
    },
    {
      title: "Visuel 3D",
      image: "/images/details/3.png",
      info: "C'est un regroupement de dessins reconstituant ta pièce de différents points de vue pour te rendre compte des espaces utilisés et du rendu illustré de ton futur projet."
    },
    {
      title: "Plan à l'échelle",
      image: "/images/details/4.png",
      info: "Il permet de mieux visualiser comment les pièces sont disposées et de l'aménagement futur. Toutes les mesures sont indiquées pour réaliser un projet réaliste. "
    },
  ]
}
</script>
