<template>
  <div class="-mt-24 -ml-4 absolute w-full text-md md:-mt-20 md:text-xl font-display text-center">- On peut connaître une personne grâce à la décoration de sa maison -</div>

  <div class="md:flex md:-ml-16 md:-mr-16 md:justify-center md:items-center">
    <img src="/images/marie.jpg" class="w-96 h-96 shadow-md m-2">
    <div class="md:flex-1 p-4 bg-white font-light shadow-md m-2">
      Je suis Marie Vanheule
      <br><br>
      Depuis toute petite, j'adore la décoration et à même pas 5 ans j'avais déjà un rouleau dans les mains pour repeindre ma chambre (dans mes souvenirs c'était plus mes mains dans la peinture mais passons ce détail...)
      <br><br>
      Ça n'a pas manqué, j'ai suivi un cursus en aménagement et finition puis une formation de designer d'espace à la MJM Graphic Design de Lille.
      <br>
      Diplômée en tant que décoratrice d'intérieur, je me lance dans l'aventure pour te savoir bien chez toi ! 
      <br><br>
      Je suis une acharnée de marchés aux puces pour trouver des objets vintage que j'adore marier avec des objets plus design et contemporains.
      <br>
      J'ai un penchant affirmé pour les styles Art Déco, Anglais et Art Nouveau que tu retrouveras dans mes projets par petites notes mais je m'adapte à tous les styles.
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class APropos extends Vue {

}
</script>
