<template>
  <div class="-mt-20 -ml-4 absolute w-full text-2xl font-display text-center">- Politique de confidentialité -</div>

  <div class="md:flex-1 p-4 bg-white font-light shadow-md m-2">
    Au regards des données transmises par l'intermédiaire du formulaire de devis,<br>
    Celles-ci sont connues de et utilisées par VNHinterior afin de procéder à des échanges par email.<br><br>

    Ces données sont supprimées des serveurs de notre hébergeur dès transfert par email à VNHinterior.<br>
    Vous êtes libres de demander la suppression de ces données a tout moment via l'un des moyens de contact disponibles en bas de page.
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class PDC extends Vue {

}
</script>
