<template>
  <div class="md:container lg:px-64 mx-auto">
    <div class="hidden md:flex justify-around pt-4">
      <div v-for="link in links" :key="link" class="flex-grow border-t border-b border-gray-50 text-gray-200 text-center p-1">
        <router-link :active-class="'text-black'" :to="link.to">{{ link.title }}</router-link>
      </div>
    </div>

    <div>
      <img src="/images/logo.png" class="mx-auto p-12 w-80 pixel-perfect">
    </div>

    <div class="flex justify-center md:justify-start">
      <socials/>
    </div>

    <div class="border mt-20 p-4 relative bg-pink-regular">
      <router-view :key="$route.fullPath" />
    </div>

    <div class="hidden md:flex justify-around pt-12">
      <div class="flex-1 text-center"> vnhinterior@gmail.com </div>
      <div class="flex flex-1 justify-center"> <socials/> </div>
      <div class="flex-1 text-center"> Tel : 06.05.27.61.82 </div>
    </div>

    <div class="flex justify-around p-4 text-xs">
      <router-link to="/mentions-legales" class="flex-1 text-center"> Politique de confidentialité </router-link>
      <div class="flex-auto text-center font-bold"> Propriété de vnhinterior <br> Tous droits réservés </div>
      <router-link to="/cookies" class="flex-1 text-center"> Cookies et informations personnelles </router-link>
    </div>
  </div>

  <div v-if="phoneMenuToggled" class="fixed z-40 top-0 bottom-0 left-0 right-0 bg-white">
    <img src="/images/logo.png" class="mx-auto p-12 w-80 pixel-perfect">

    <div v-for="link in links" :key="link" class="text-center p-4">
      <router-link :to="link.to" v-on:click="togglePhoneMenu()" class="text-xl border-b p-4">{{ link.title }}</router-link>
    </div>
    
    <div class="flex justify-center pt-4"> <socials/> </div>

    <div class="flex justify-around pt-4">
      <div class="flex-1 text-center"> vnhinterior@gmail.com </div>
      <div class="flex-1 text-center"> Tel : 06.05.27.61.82 </div>
    </div>
  </div>

  <div v-on:click="togglePhoneMenu()" class="z-50 md:hidden button-white-invert text-black fixed bottom-24 right-4 w-16 h-16 shadow-lg rounded-full text-4xl text-center">
    ...
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Socials from '@/components/socials.vue';

@Options({
  components: {
    Socials
  },
})
export default class App extends Vue {
  links = [
    {to: '/', title: 'Accueil'},
    {to: '/services', title: 'Services'},
    {to: '/projets', title: 'Projets'},
    {to: '/devis', title: 'Devis'},
    {to: '/apropos', title: 'A Propos'},
  ];

  phoneMenuToggled = false;
  togglePhoneMenu () {
    this.phoneMenuToggled = !this.phoneMenuToggled;
  }
}
</script>