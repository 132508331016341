
import { Options, Vue } from 'vue-class-component';
import Socials from '@/components/socials.vue';

@Options({
  components: {
    Socials
  },
})
export default class App extends Vue {
  links = [
    {to: '/', title: 'Accueil'},
    {to: '/services', title: 'Services'},
    {to: '/projets', title: 'Projets'},
    {to: '/devis', title: 'Devis'},
    {to: '/apropos', title: 'A Propos'},
  ];

  phoneMenuToggled = false;
  togglePhoneMenu () {
    this.phoneMenuToggled = !this.phoneMenuToggled;
  }
}
