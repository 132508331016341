import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Accueil from '../views/Accueil.vue';
import Services from '../views/Services.vue';
import Projets from '../views/Projets.vue';
import Devis from '../views/Devis.vue';
import APropos from '../views/APropos.vue';
import PDC from '../views/PDC.vue';
import Cookies from '../views/Cookies.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/projets',
    name: 'Projets',
    component: Projets,
    children: [
      { path: ':file', component: Projets }
    ]
  },
  {
    path: '/devis',
    name: 'Devis',
    component: Devis
  },
  {
    path: '/apropos',
    name: 'APropos',
    component: APropos
  },
  {
    path: '/mentions-legales',
    name: 'PDC',
    component: PDC
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
