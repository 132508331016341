<template>
  <div class="-mt-20 -ml-4 absolute w-full text-5xl text-center font-extralight">Bienvenue</div>

  <div class="text-md text-center font-display">- Envie de refaire ton intérieur ? -</div>
  <div class="mt-2 text-md text-center font-light">Découvre mon univers !</div>

  <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap md:-ml-16 md:-mr-16 justify-around">
    <div class="p-4 flex-1">
      <router-link :to="'/services'">
        <img src="/images/services.png" class="mx-auto shadow-md">
        <button class="button-white-invert font-display text-black uppercase text-sm block mx-auto mt-2">Services</button> 
      </router-link>
    </div>

    <div class="p-4 flex-1">
      <router-link :to="'/projets'">
        <img src="/images/projets.jpg" class="mx-auto shadow-md">
        <button class="button-white-invert font-display text-black uppercase text-sm block mx-auto mt-2">Projets</button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Accueil extends Vue {

}
</script>
