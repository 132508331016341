
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Devis extends Vue {
  form : {[x in string]: any} = {
    nom: '',
    tel: '',
    mail: '',
    ville: '',
    status: 'Propriétaire',
    type: 'maison',
    superficie: '',
    pieces: '',
    desc: ''
  }

  empty(input: string) {
    return this.form[input] == '';
  }
}
